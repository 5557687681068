import React, { Component } from "react"
import { Link } from "gatsby"
import MediaQuery from 'react-responsive'
import VolunteerHeader from "../components/volunteerHeader"
import { Formik } from "formik"
import SEO from "../components/seo"







export default class Volunteer extends Component {

  async sendFormData(values) {
    //For Production:
    const API_URL = "https://us-central1-sf2020-bd929.cloudfunctions.net/usage";
    //For Dev: 
    //const API_URL = "https://us-central1-sf2020-devel.cloudfunctions.net/volunteer";
    const data = {
      name: values.name,
      email: values.email,
      message: values.message
    };
    fetch(API_URL, {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    }).then(response => console.log(response.status)); // do something
  }
  constructor(props) {
    super(props);
    this.state = {
      show_overlay: false
    };
  }

  render() {
    return (
      <div>
        <SEO title="Volunteer" />
        <MediaQuery maxDeviceWidth={1224}>
          <div>
            <div style={{ position: 'absolute',width:"100vw" }}>
              <div class="mobile-headerContainer">
                <a href="#" onClick={(event) => this.setState({ show_overlay: true })}>
                  <div class="mobile-headerOverlayButtonPlaceholder">
                  <img alt="menu-button" src={require('../images/menu_icon.svg')} class="mobile-headerButton"/>
                  </div>
                  </a>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <text class="mobile-headerText">
                    <text style={{ color: "#FF4747" }}>Students</text>
                    <text style={{ color: "#000000" }}> For </text>
                    <text style={{ color: "#5793FA" }}>2020</text>
                  </text>
                </Link>
                <Link to="/map/" style={{ textDecoration: 'none' }}>
                  <div class="mobile-headerVoteButton">
                    <text class="mobile-headerVoteButtonTexts">Vote</text>
                  </div>
                </Link>
              </div>

              <div class="mobile-volunteer-bodyContainer">
                <text class="mobile-volunteer-titleText">
                  Volunteer Your<br />
                  <text style={{ color: "#5793FA" }}>Time</text>
                </text>
                <text class="mobile-volunteer-subtitleText">
                  We need your help! To keep<br />
              expanding, we need to keep<br />
              growing our volunteer<br />
              networks, and that starts<br />
              with you!
            </text>

                <text class="mobile-volunteer-primaryText">Call us</text>
                <text class="mobile-volunteer-secondaryText">(404)-987-1503</text>
                <text class="mobile-volunteer-primaryText" style={{ marginLeft: "44.27vw" }}>Email us</text>
                <text class="mobile-volunteer-secondaryText" style={{ marginLeft: "44.27vw" }}>studentsfor2020@gmail.com</text>
               
                <Formik
                
            initialValues={{ email: "", name: "", message: "" }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
              const errors = {}
              if (!values.email) {
                errors.email = "Please enter in an email address."
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address"
              } else if (!values.message) {
                errors.message = "Please enter in a message."
              } else if (!values.name) {
                errors.name = "Please enter in a name."
              }
              return errors
            }}
            onSubmit={async (values, { resetForm }) => {
              resetForm();
              this.sendFormData(values);
             
              
            }}
          >
            {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
              
          }) => (
                 <form onSubmit={handleSubmit}>
                <div class="mobile-volunteer-basicInputBox" style={{ marginTop: "39.53vh"}}>
                  <input type="mobile-volunteerInput" id="nameV" name="name" placeholder="Name" onChange={handleChange} value={values.name} onBlur={handleBlur}/>
                </div>
                <div class="mobile-volunteer-basicInputBox" style={{ marginTop: "44.7vh" }}>
                  <input type="mobile-volunteerInput" id="emailV" name="email" placeholder="Email Address" onChange={handleChange} value={values.email} onBlur={handleBlur}/>
                </div>
                <div class="mobile-volunteer-longInputBox">
                  <input type="mobile-volunteerInput" id="messageV" name="message" placeholder="Your Message" onChange={handleChange} value={values.message} onBlur={handleBlur} />
                </div>
                {errors.name && touched.name && (
                    <div className="mobile-input-feedback">{errors.name}</div>
                  )}
                  {errors.email && touched.email && (
                    <div className="mobile-input-feedback">{errors.email}</div>
                  )}
                  {errors.message && touched.message && (
                    <div className="mobile-input-feedback">{errors.message}</div>
                  )}
                <button
                  type="submit"
                  class="volunteer-SubmitForm"
                  disabled={isSubmitting}
                  style={{marginTop:"70vh"}}
                >
                  <text class="volunteer-SubmitFormText">Submit</text>
                </button>
                </form>
          )}
                </Formik>
              </div>
              <div class="mobile-footerContainer">
                <text class="mobile-footerText">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
                <text class="mobile-footerSmallText">2020 © All Rights Reserved</text>
              </div>
            </div>

            <div class="mobile-overlayContainer" style={{ display: this.state.show_overlay === true ? 'block' : 'none' }}>
              <Link to="/" style={{ textDecoration: 'none', marginTop: '5.97vh', position: "absolute" }}><text class="mobile-overlayText">Home</text> </Link>
              <Link to="/about/" style={{ textDecoration: 'none', marginTop: '11.37vh', position: "absolute" }}><text class="mobile-overlayText">Contact Us</text> </Link>
              <Link to="/press/" style={{ textDecoration: 'none', marginTop: '16.77vh', position: "absolute" }}><text class="mobile-overlayText">Press</text> </Link>
              <Link to="/volunteer/" style={{ textDecoration: 'none', marginTop: '22.17vh', position: "absolute" }}><text class="mobile-overlayText">Volunteer</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none', marginTop: '27.57vh', position: "absolute" }}><text class="mobile-overlayText">Where To Vote</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none' }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div class="mobile-overlayDarken" onClick={(event) => this.setState({ show_overlay: false })} style={{ display: this.state.show_overlay === true ? 'block' : 'none' }} />
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1200} maxDeviceHeight={600} >
          <div>
            <div style={{ position: 'absolute' }}>
              <div class="mobile-headerContainer">
                <a href="#" onClick={(event) => this.setState({ show_overlay: true })}>
                  <div class="mobile-headerOverlayButtonPlaceholder">
                  <img alt="menu-button" src={require('../images/menu_icon.svg')} class="mobile-headerButton"/>
                  </div>
                  </a>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <text class="mobile-headerText">
                    <text style={{ color: "#FF4747" }}>Students</text>
                    <text style={{ color: "#000000" }}> For </text>
                    <text style={{ color: "#5793FA" }}>2020</text>
                  </text>
                </Link>
                <Link to="/map/" style={{ textDecoration: 'none' }}>
                  <div class="mobile-headerVoteButton">
                    <text class="mobile-headerVoteButtonTexts">Vote</text>
                  </div>
                </Link>
              </div>

              <div class="mobile-volunteer-bodyContainer">
                <text class="mobile-volunteer-titleText" style={{marginBottom:"70vh" }}>
                  Volunteer Your<br />
                  <text style={{ color: "#5793FA" }}>Time</text>
                </text>
                <text class="mobile-volunteer-subtitleText" style={{visibility:"hidden"}}>
                  We need your help! To keep<br />
              expanding, we need to keep<br />
              growing our volunteer<br />
              networks, and that starts<br />
              with you!
            </text>

                <text class="mobile-volunteer-primaryText">Call us</text>
                <text class="mobile-volunteer-secondaryText">(404)-987-1503</text>
                <text class="mobile-volunteer-primaryText" style={{ marginLeft: "44.27vw" }}>Email us</text>
                <text class="mobile-volunteer-secondaryText" style={{ marginLeft: "44.27vw" }}>studentsfor2020@gmail.com</text>
              
                <Formik
            initialValues={{ email: "", name: "", message: "" }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
              const errors = {}
              if (!values.email) {
                errors.email = "Please enter in an email address."
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address"
              } else if (!values.message) {
                errors.message = "Please enter in a message."
              } else if (!values.name) {
                errors.name = "Please enter in a name."
              }
              return errors
            }}
            onSubmit={async (values, { resetForm }) => {
              resetForm();
              this.sendFormData(values);
             
              
            }}
          >
            {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
              
          }) => (
                 <form onSubmit={handleSubmit}>
                <div class="mobile-volunteer-basicInputBox" style={{ marginTop: "39.53vh" }}>
                  <input type="mobile-volunteerInput" id="nameV" name="name" placeholder="Name" onChange={handleChange} value={values.name} onBlur={handleBlur}/>
                </div>
                <div class="mobile-volunteer-basicInputBox" style={{ marginTop: "44.7vh" }}>
                  <input type="mobile-volunteerInput" id="emailV" name="email" placeholder="Email Address" onChange={handleChange} value={values.email} onBlur={handleBlur}/>
                </div>
                <div class="mobile-volunteer-longInputBox">
                <textarea
                      type="mobile-volunteerInput"
                      id="messageV"
                      name="message"
                      placeholder="Your Message"
                      onChange={handleChange}
                      value={values.message}
                      onBlur={handleBlur}
                    />

                </div>
                {errors.name && touched.name && (
                    <div className="mobile-input-feedback">{errors.name}</div>
                  )}
                  {errors.email && touched.email && (
                    <div className="mobile-input-feedback">{errors.email}</div>
                  )}
                  {errors.message && touched.message && (
                    <div className="mobile-input-feedback">{errors.message}</div>
                  )}
                <button
                  type="submit"
                  class="volunteer-SubmitForm"
                  disabled={isSubmitting}
                  style={{marginTop:"70vh"}}
                >
                  <text class="volunteer-SubmitFormText">Submit</text>
                </button>
                </form>
          )}
                </Formik>
              </div>
              <div class="mobile-footerContainer">
                <text class="mobile-footerText">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
                <text class="mobile-footerSmallText">2020 © All Rights Reserved</text>
              </div>
            </div>

            <div class="mobile-overlayContainer" style={{ display: this.state.show_overlay === true ? 'block' : 'none' }}>
              <Link to="/" style={{ textDecoration: 'none', marginTop: '5.97vh', position: "absolute" }}><text class="mobile-overlayText">Home</text> </Link>
              <Link to="/about/" style={{ textDecoration: 'none', marginTop: '11.37vh', position: "absolute" }}><text class="mobile-overlayText">Contact Us</text> </Link>
              <Link to="/press/" style={{ textDecoration: 'none', marginTop: '16.77vh', position: "absolute" }}><text class="mobile-overlayText">Press</text> </Link>
              <Link to="/volunteer/" style={{ textDecoration: 'none', marginTop: '22.17vh', position: "absolute" }}><text class="mobile-overlayText">Volunteer</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none', marginTop: '27.57vh', position: "absolute" }}><text class="mobile-overlayText">Where To Vote</text> </Link>
              <Link to="/map/" style={{ textDecoration: 'none' }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div class="mobile-overlayDarken" onClick={(event) => this.setState({ show_overlay: false })} style={{ display: this.state.show_overlay === true ? 'block' : 'none' }} />
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1224}>
        <div>
      <div class="topBar">
          <div class = "logoDiv">
          <Link to="/" style={{ textDecoration: 'none'}}>
            <text class="students-for-2020">
              <text style={{ color: "#FF4747",}}>Students</text>
              <text style={{ color: "#000000" }}> For </text>
              <text style={{ color: "#5793FA" }}>2020</text>
            </text>
          </Link>
          </div>
        <div class = "headerDiv">
          
            <Link to="/press/" style={{ textDecoration: 'none'}}>
              <text class="headerText">Press</text>
            </Link>
            
            <Link to="/volunteer/"  style={{ textDecoration: 'none'}}>
              <text class = "headerText">Volunteer</text>
            </Link>
            <Link to="/about"  style={{ textDecoration: 'none'}}>
              <text class = "headerText">Contact Us</text>
            </Link>

          </div>
        <div class="headButtDiv">
          <a class="headerButton" href="/map" style={{ textDecoration: 'none'}}>
            <text class="headerButtonText">Where Should I Vote?</text>
          </a>
        </div>
      </div>
      <div class="volunteer-TopImage">
        <VolunteerHeader>
          <div class="volunteer-TopImageCover">
            <text class="volunteer-TopImageText">Volunteer</text>
          </div>
        </VolunteerHeader>
      </div>
      <div class="volunteer-bodyDiv">
       
        <div class="volunteer-leftDiv">
        <div class="volunteer-leftContainer">
          <text style={{marginRight:"24vw" }} class="volunteer-SubtitleText">Support the cause</text>
          <text style={{marginRight:"20vw"}} class="volunteer-TitleText">
            <text style={{ color: "#000000" }}>
              Volunteer Your
              <br />
            </text>
            <text style={{ color: "#5793FA"}}>Time</text>
          </text>
          <text class="volunteer-NormalText" style={{ marginLeft: 0 }}>
            We need your help! To keep expanding, we need to keep <br />
            growing our volunteer networks, and that starts with you!
          </text>
          </div>
          <div class="volunteerInfo-Div">
            
          </div>
        </div>
        <div class="volunteer-rightDiv">
          <Formik
            initialValues={{ email: "", name: "", message: "" }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={values => {
              const errors = {}
              if (!values.email) {
                errors.email = "Please enter in an email address."
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address"
              } else if (!values.message) {
                errors.message = "Please enter in a message."
              } else if (!values.name) {
                errors.name = "Please enter in a name."
              }
              return errors
            }}
            onSubmit={async (values, { resetForm }) => {
              resetForm();
              this.sendFormData(values);
             
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div class="volunteer-Form">
                  <div class="volunteer-NameBox">
                    <input
                      type="volunteerNameEmailInput"
                      id="nameV"
                      name="name"
                      placeholder="Name"
                      onChange={handleChange}
                      value={values.name}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div class="volunteer-EmailBox">
                    <input
                      type="volunteerNameEmailInput"
                      id="emailV"
                      name="email"
                      placeholder="Email Address"
                      onChange={handleChange}
                      value={values.email}
                      onBlur={handleBlur}
                    />
                  </div>

                  <div class="volunteer-MessageBox">
                    <textarea
                      type="volunteerMessageInput"
                      id="messageV"
                      name="message"
                      placeholder="Your Message"
                      onChange={handleChange}
                      value={values.message}
                      onBlur={handleBlur}
                    />
                  </div>
                  {errors.name && touched.name && (
                    <div className="input-feedback">{errors.name}</div>
                  )}
                  {errors.email && touched.email && (
                    <div className="input-feedback">{errors.email}</div>
                  )}
                  {errors.message && touched.message && (
                    <div className="input-feedback">{errors.message}</div>
                  )}
                </div>
                <button
                  type="submit"
                  class="volunteer-SubmitForm"
                  disabled={isSubmitting}
                >
                  <text class="volunteer-SubmitFormText">Submit</text>
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>

      <div class="bottomBar">
        <Link to="/" style={{ textDecoration: 'none'}}>
          <text class="students-for-2020">
            <text style={{ color: "#FF4747" }}>Students</text>
            <text style={{ color: "#ffffff" }}> For </text>
            <text style={{ color: "#5793FA" }}>2020</text>
          </text>
        </Link>
        <div class="rightsReservedDiv">
          <text class="rightsReserved">2020 © All Rights Reserved</text>
        </div>
      </div>
    </div>
        </MediaQuery>
      </div>
    );
  }
}
